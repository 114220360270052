<template>
  <div class="input-group">
    <flat-picker
      :config="config"
      class="form-control datepicker"
      v-model="dateRange"
      placeholder="Dates"
    >
    </flat-picker>
    <div class="input-group-append">
      <i class="fal fa-calendar-alt"></i>
    </div>
  </div>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import { Select, Option, Button } from "element-ui";
import { French } from "flatpickr/dist/l10n/fr.js";

export default {
  name: "date-range-selector",

  components: {
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: {
    value: {
      type: String | Array,
      default: null,
    },
  },

  data() {
    return {
      dateRange: null,
    };
  },

  setup() {},

  created() {},

  computed: {
    config: function () {
      const config = {
        allowInput: true,
        mode: "range",
        plugins: [],
        locale: { rangeSeparator: " - " },
      };

      if (this.locale) {
        config.locale = this.locale;
      }

      return config;
    },

    locale: function () {
      let locale = null;

      if (this.$i18n.locale == "fr") {
        locale = French;

        locale.rangeSeparator = " - ";
      }
      return locale;
    },
  },

  methods: {
    getLocale() {},
  },

  watch: {
    dateRange() {
      if (this.dateRange != null) {
        let dateParts = this.dateRange.split(" - ");
        if (dateParts.length == 1) {
          dateParts[1] = dateParts[0];
        }
        this.$emit("onDateRangeChanged", dateParts);
      }
    },
    value() {
      if (!this.value) {
        this.dateRange = null;
      }

      const dateRange = this.value?.join(" - ");

      if (dateRange != this.dateRange) {
        this.dateRange = dateRange;
      }
    },
  },
};
</script>
<style></style>
